import {
  DATA_LOADED,
  DATA_LOADING,
  FETCH_LOADED,
  FETCH_LOADING,
  GET_ITEM_DEPENDENCY,
  GET_STATUS,
  REMOVE_ITEM_DEPENDENCY,
  REMOVE_MESSAGE,
  SET_CURRENT,
  SET_MESSAGE,
  SupportActionType,
  SupportState
} from "./types";

const defaultStatus = {
  id: '',
  isSuccess: false,
  message: ''
}

const initialState: SupportState = {
  current: null,
  isLoading: false,
  fetchLoading: false,
  message: '',
  dependency: null,
  status: defaultStatus
}

export default function(state = initialState, action: SupportActionType): SupportState {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case DATA_LOADED:
      return {
        ...state,
        isLoading: false
      }
    case FETCH_LOADING:
      return {
        ...state,
        fetchLoading: true
      }
    case FETCH_LOADED:
      return {
        ...state,
        fetchLoading: false
      }
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload
      }
    case GET_STATUS:
      return {
        ...state,
        status: action.payload
      }
    case SET_MESSAGE:
    case REMOVE_MESSAGE:
      return {
        ...state,
        message: action.payload
      }
    case GET_ITEM_DEPENDENCY:
    case REMOVE_ITEM_DEPENDENCY:
      return {
        ...state,
        dependency: action.payload
      }
    default:
      return state
  }
}